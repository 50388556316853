import React from "react";
import { RotatingLines } from "react-loader-spinner";

const Spinner = () => {
  return (
    <>
      <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-80 z-50">
        <RotatingLines
          strokeColor="grey"
          strokeWidth="2"
          animationDuration="0.75"
          width="66"
          visible={true}
        />
      </div>
    </>
  );
};

export default Spinner;
