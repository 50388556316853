import React, { useEffect, useState } from "react";
import { getData, postData, putData } from "../FatchApi/FatchApi";

import Spinner from "./Loader/Spinner";

const ProductAddForm = ({ id }) => {

  const initialFormData = {
    title: id.title ? id.title : "",
    metatitle: id.metatitle ? id.metatitle : "",
    productDescription: id.productDescription ? id.productDescription : "",
    metaDescription: id.metaDescription ? id.metaDescription : "",
    metaKeywords: id.metaKeywords ? id.metaKeywords : "",
    slug: id.slug ? id.slug : "",
    quantity: id.quantity ? id.quantity : "",
    images: id.images ? id.images : "", 
    category: id.category?.length !== 0 ? id.category?.map((cat) => cat._id) : [],
    mrp: id.mrp ? id.mrp : "",
    discount: id.discount ? id.discount : 0,
    sellingPrice: id.sellingPrice ? id.sellingPrice : "",
    hsn: id.hsn ? id.hsn : "",
    tax: id.tax ? id.tax : "",
    sku: id.sku ? id.sku : "",

    length: id.length ? id.length : "",
    breadth: id.breadth ? id.breadth : "",
    height: id.height ? id.height : "",
    weight: id.weight ? id.weight : "",
    type: id.type ? id.type : "",
    variants: id.variants?.length !== 0 ? id.variants  : [{ size: '', color: '', stock: 0 }],
  };
 
  const [category, setCategory] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [productData, setProductData] = useState(initialFormData);
  const [sellingPrice, setSellingPrice] = useState(id?.sellingPrice);
  const [inStock, setInstock] = useState(id?.inStock);
  const [variants, setVariants] = useState(id.variants &&  id.variants?.length != 0 ? id.variants  : [{ size: '', color: '', stock: 0 }]);

  const handleChange = (e) => {
    const { name, options } = e.target;

    if (name === "category") {
      const selectedValues = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      setProductData((prevData) => ({
        ...prevData,
        [name]: selectedValues,
      }));
    } else {
      const { value } = e.target;
      setProductData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleFileChange = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);
    const fileURLs = fileArray.map((file) => file[0]);
    setProductData((prevData) => ({
      ...prevData,
      images: fileArray,
      imageUrls: fileURLs,
    }));
  };
  const handleStock = () => {
    setInstock(!inStock);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(variants)
    setShowSpinner(true);
    const contanttype = "";
    const formData = new FormData();
    formData.append("title", productData.title);
    formData.append("metatitle", productData.metatitle);
    formData.append("productDescription", productData.productDescription);
    formData.append("metaDescription", productData.metaDescription);
    formData.append("metaKeywords", productData.metaKeywords);
    formData.append("slug", productData?.slug);
    formData.append("quantity", productData?.quantity);
    formData.append("inStock", inStock);
    formData.append("mrp", productData.mrp);
    formData.append("discount", productData.discount);
    formData.append("sku", productData.sku);
    formData.append("tax", productData.tax);
    formData.append("hsn", productData.hsn);
    formData.append("EanCode", productData.ean);
    formData.append("sellingPrice", sellingPrice);
    formData.append("length", productData.length);
    formData.append("breadth", productData.breadth);
    formData.append("height", productData.height);
    formData.append("weight", productData.weight);
    formData.append('variants', JSON.stringify(variants));
    if (productData.images !== 0 || productData.images !== " ") {
      productData.images?.forEach((image, index) => {
        formData.append(`images`, image);
      });
    }
    productData.category?.forEach((category, index) => {
      formData.append("category", category);
    });

    if (id) {
      const Id = id._id;
      const res = await putData(`api/products/update-products/${Id}`, {
        data: formData,
        contanttype,
      });

      if (res && res.status === "success") {
        window.location.reload();
      } else {
        alert(res?.message);
      }
    } else {
      const res = await postData("api/products/add-products", {
        data: formData,
        contanttype,
      });
      if (res && res.status === "success") {
        window.location.reload();
      } else {
        setShowSpinner(false);
        alert(res?.message);
      }
    }
  };
  const getCategory = async () => {
    const data = await getData("api/category/get-category");
    setCategory(data.category);
  };

  const handleVariantChange = (index, field, value) => {
    const newVariants = [...variants];
    newVariants[index][field] = value;
    setVariants(newVariants);
  };
  const addVariant = () => {
    setVariants([...variants, { size: '', color: '', stock: 0 }]);
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <>
      <div className="relative min-h-screen">
        {/* <Loader /> */}
        {showSpinner ? <Spinner /> : " "}
        <form
          encType="multipart/form-data"
          className="grid grid-cols-2 gap-4 mx-auto"
        >
          <div className="mb-4">
            <label htmlFor="category" className="block mb-1 font-medium">
              Category
            </label>
            <select
              className="w-full px-3 py-2 border border-gray-300 rounded"
              id="category"
              name="category"
              value={productData.category}
              onChange={handleChange}
              required
              multiple
            >
              <option value="">Select Product Category</option>
              {category.map((item) => (
                <option
                  value={item._id}
                  selected={initialFormData?.category == item._id}
                >
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="title" className="block mb-1 font-medium">
              Title
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              id="title"
              placeholder="Enter product title"
              name="title"
              value={productData.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="productDescription"
              className="block mb-1 font-medium"
            >
              Meta Description
            </label>
            <textarea
              className="w-full px-3 py-2 border border-gray-300 rounded bg-light"
              rows="3"
              placeholder="Enter meta description"
              id="metaDescription"
              name="metaDescription"
              value={productData.metaDescription}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="mb-4">
            <label
              htmlFor="productDescription"
              className="block mb-1 font-medium"
            >
              Product Description
            </label>
            <textarea
              className="w-full px-3 py-2 border border-gray-300 rounded bg-light"
              rows="3"
              placeholder="Enter product description"
              id="productDescription"
              name="productDescription"
              value={productData.productDescription}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <label htmlFor="title" className="block mb-1 font-medium">
              Meta Title
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              id="metatitle"
              name="metatitle"
              placeholder="Enter meta title"
              value={productData.metatitle}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="metaKeywords" className="block mb-1 font-medium">
              Meta Keywords
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              id="metaKeywords"
              name="metaKeywords"
              value={productData.metaKeywords}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="metaKeywords" className="block mb-1 font-medium">
              Product URL
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              id="metaKeywords"
              name="slug"
              placeholder="product-url-format"
              disabled={id.slug ? true : false}
              value={productData.slug}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="quantity" className="block mb-1 font-medium">
              Quantity
            </label>
            <input
              type="number"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Enter quantity(50)"
              id="quantity"
              name="quantity"
              value={productData.quantity >= 0 ? productData.quantity : ""}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mrp" className="block mb-1 font-medium">
              MRP
            </label>
            <input
              type="number"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Enter MRP Rs"
              id="mrp"
              name="mrp"
              value={productData.mrp >= 0 ? productData.mrp : ""}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mrp" className="block mb-1 font-medium">
              Discount %
            </label>
            <input
              type="number"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="0"
              id="discount"
              name="discount"
              value={productData.discount}
              onChange={(e) => {
                handleChange(e);
                setSellingPrice(
                  Math.floor(
                    productData.mrp - (productData?.mrp * e.target.value) / 100
                  )
                );
              }}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="sellingPrice" className="block mb-1 font-medium">
              Selling Price
            </label>
            <input
              type="number"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Enter selling price Rs"
              id="sellingPrice"
              name="sellingPrice"
              value={sellingPrice}
              onChange={(e) => setSellingPrice(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="sku" className="block mb-1 font-medium">
              SKU
            </label>
            <input
              type="text"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Enter SKU (PRA8765)"
              id="sku"
              name="sku"
              value={productData.sku}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="tax" className="block mb-1 font-medium">
              TAX
            </label>
            <select
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Enter Tax"
              id="tax"
              name="tax"
              value={productData.tax}
              onChange={handleChange}
              required
            >
              <option selected> Select One</option>
              <option value={0}>GST_0</option>
              <option value={12}>GST_12</option>
              <option value={18}>GST_18</option>
              <option value={28}>GST_28</option>
              <option value={3}>GST_3</option>
              <option value={5}>GST_5</option>
              <option value={"GST_APPAREL"}>GST_APPAREL</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="mrp" className="block mb-1 font-medium">
              HSN
            </label>
            <input
              type="number"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Enter HSN(76543567)"
              id="hsn"
              name="hsn"
              value={productData.hsn}
              onChange={handleChange}
              required
            />
          </div>
   <div className="mb-4">
            <label htmlFor="mrp" className="block mb-1 font-medium">
              EAN Code
            </label>
            <input
              type="number"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Enter Ean(87654589) "
              id="ean"
              name="ean"
              value={productData.ean}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="weight" className="block mb-1 font-medium">
              Weight in (g)
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              id="weight"
              name="weight"
              placeholder="Enter product weight(500g)"
              value={productData.weight}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="image" className="block mb-1 font-medium">
              Image Upload
            </label>
            <input
              type="file"
              id="images"
              name="images"
              onChange={handleFileChange}
              multiple
            />
          </div>
          <div className="flex items-center mb-4">
            <input
              id="default-checkbox"
              type="checkbox"
              checked={inStock}
              onChange={handleStock}
              defaultValue
              className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="default-checkbox"
              className="ms-4 text-lg font-medium text-green-600 dark:text-green-400"
            >
              InStock
            </label>
          </div>
        </form>
        {variants?.map((variant, index) => (
          <div key={index}> 
            <input
              className="m-1 border"
              type="text"
              value={variant.color}
              onChange={(e) => handleVariantChange(index, 'color', e.target.value)}
              placeholder="Color"
            />
            <input
              className="m-1 border"
              type="number"
              value={variant.stock}
              onChange={(e) => handleVariantChange(index, 'stock', e.target.value)}
              placeholder="Stock"
            />
          </div>

        ))}
        <button type="button" className="px-2 py-1 bg-blue-500 rounded text-white hover:bg-blue-900" onClick={addVariant}>Add Variant</button>
        <div className="text-center">
            <button onClick={handleSubmit} className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-900">
              Save Product
            </button>
          </div>
      </div>
    </>
  );
};

export default ProductAddForm;
