import React, { useEffect, useState } from "react";
import PageWrapper from "../components/PageWrapper";
import { DeleteData, getData, postData, putData2 } from "../FatchApi/FatchApi";
import DataTable from "react-data-table-component";
const ProductCategory = () => {
  const [categoryName, setCotegoryName] = useState("");
  const [Allcategory, setAllCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [buttontype, setButtonType] = useState("Add");
  const getCategory = async () => {
    const res = await getData("api/category/get-category");

    setAllCategory(res.category);
  };
  const addCategory = async () => {
    const body = {
      name: categoryName,
    };
    if (categoryName !== "") {
      const formData = {
        contanttype: "application/json",
        data: JSON.stringify(body),
      };
      const res = await postData("api/category/add-category", formData);

      if (res.success) {
        alert(res.message);
        setCotegoryName("");
        getCategory();
      } else {
        alert(res.err.message);
        getCategory();
        setCotegoryName("");
      }
    } else {
      alert("Please enter category name");
      getCategory();
    }
  };
  const updateCateogry = async () => {
    const body = {
      name: categoryName,
    };
    const formData = {
      data: JSON.stringify(body),
    };
    const res = await putData2(
      `api/category/update-category/${categoryId}`,
      formData
    );
    if (res.success) {
      alert(res.message);
      getCategory();
      setCotegoryName("");
      setCategoryId("");
      setButtonType("Add");
    } else {
      alert("Category not updated");
    }
    console.log(res);
  };
  const deleteCategory = async (categoryId) => {
    const res = await DeleteData(`api/category/delete-category/${categoryId}`);
    console.log(res);
    if (res.success) {
      alert("Category has deleted");
      getCategory();
    } else {
      alert("something went wrong");
    }
  };
  useEffect(() => {
    getCategory();
  }, []);

  const columns = [
    {
      name: "SR.Number",
      selector: (row, i) => 1 + i,
      sortable: true,
      center: true,
    },
    {
      name: "Category Name",
      selector: (row, i) => row.name,
      sortable: true,
      center: true,
    },
    {
      name: "Number of Product",
      selector: (row, i) => row.productId?.length,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row, i) => (
        <div className=" flex flex-row ">
          <div className="mx-2">
            <svg
              role="button"
              onClick={() => {
                setCotegoryName(row.name);
                setButtonType("update");
                setCategoryId(row._id);
              }}
              version="1.1"
              className="text-blue-600"
              fill="currentColor"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              height="20px"
              width="20px"
              viewBox="0 0 348.882 348.882"
              style={{ enableBackground: "new 0 0 348.882 348.882" }}
              xmlSpace="preserve"
            >
              <g>
                <path
                  d="M333.988,11.758l-0.42-0.383C325.538,4.04,315.129,0,304.258,0c-12.187,0-23.888,5.159-32.104,14.153L116.803,184.231
		c-1.416,1.55-2.49,3.379-3.154,5.37l-18.267,54.762c-2.112,6.331-1.052,13.333,2.835,18.729c3.918,5.438,10.23,8.685,16.886,8.685
		c0,0,0.001,0,0.001,0c2.879,0,5.693-0.592,8.362-1.76l52.89-23.138c1.923-0.841,3.648-2.076,5.063-3.626L336.771,73.176
		C352.937,55.479,351.69,27.929,333.988,11.758z M130.381,234.247l10.719-32.134l0.904-0.99l20.316,18.556l-0.904,0.99
		L130.381,234.247z M314.621,52.943L182.553,197.53l-20.316-18.556L294.305,34.386c2.583-2.828,6.118-4.386,9.954-4.386
		c3.365,0,6.588,1.252,9.082,3.53l0.419,0.383C319.244,38.922,319.63,47.459,314.621,52.943z"
                />
                <path
                  d="M303.85,138.388c-8.284,0-15,6.716-15,15v127.347c0,21.034-17.113,38.147-38.147,38.147H68.904
		c-21.035,0-38.147-17.113-38.147-38.147V100.413c0-21.034,17.113-38.147,38.147-38.147h131.587c8.284,0,15-6.716,15-15
		s-6.716-15-15-15H68.904c-37.577,0-68.147,30.571-68.147,68.147v180.321c0,37.576,30.571,68.147,68.147,68.147h181.798
		c37.576,0,68.147-30.571,68.147-68.147V153.388C318.85,145.104,312.134,138.388,303.85,138.388z"
                />
              </g>
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
            </svg>
          </div>
          <div>
            <svg
              role="button"
              onClick={() => deleteCategory(row._id)}
              className="text-red-600"
              fill="currentColor"
              height="20px"
              viewBox="733 450 64.901 90"
              width="20px"
              xmlns="http://www.w3.org/2000/svg"
              isolation="isolate"
            >
              <path
                d="m755.532 457.988h19.838c.036-.129.055-.264.055-.4v-3.195c0-.369-.139-.729-.399-.988-.27-.26-.619-.41-.989-.41h-17.173c-.37 0-.719.15-.989.41-.259.259-.399.619-.399.988v3.195c0 .136.019.271.056.4zm-18.368 2.995h56.574c.309 0 .609.12.828.34.22.219.34.519.34.829v5.361c0 .31-.12.609-.34.819-.219.22-.519.35-.828.35h-56.574c-.31 0-.609-.13-.829-.35-.22-.21-.34-.509-.34-.819v-5.361c0-.31.12-.61.34-.829.22-.22.519-.34.829-.34zm29.794 64.043c0 .399-.159.779-.439 1.068-.28.28-.659.429-1.058.429-.4 0-.779-.149-1.059-.429-.279-.289-.439-.669-.439-1.068v-41.018c0-.399.16-.779.439-1.058.28-.28.659-.44 1.059-.44.399 0 .778.16 1.058.44.28.279.439.659.439 1.058zm-13.06-41.068 2.147 40.968c.02.389-.11.779-.38 1.078-.269.3-.639.47-1.038.489-.389.02-.779-.109-1.078-.379-.29-.269-.47-.639-.49-1.038l-2.146-40.958c-.02-.399.11-.789.379-1.088.27-.29.639-.47 1.039-.49.389-.02.778.12 1.078.38.289.269.469.639.489 1.038zm-12.783-12.285 3.867 63.546c0 .311.11.622.289.849.19.215.44.347.709.347h38.941c.27 0 .519-.132.709-.347.18-.227.289-.538.289-.849l3.867-63.546zm11.378-13.685h-15.334c-2.295 0-4.159 1.864-4.159 4.159v5.367c0 2.295 1.864 4.159 4.159 4.159h.96l3.867 63.544c0 2.64 1.79 4.783 3.994 4.783h38.941c2.204 0 3.994-2.143 3.994-4.783l3.867-63.544h.96c2.295 0 4.159-1.864 4.159-4.159v-5.367c0-2.295-1.864-4.159-4.159-4.159h-15.334c.012-.131.018-.263.018-.397v-3.2c0-2.423-1.968-4.391-4.391-4.391h-17.168c-2.424 0-4.391 1.968-4.391 4.391v3.2c0 .134.006.266.017.397zm27.515 26.13-2.146 40.958c-.02.399-.2.769-.49 1.038-.299.27-.689.399-1.078.379-.399-.019-.769-.189-1.038-.489-.27-.299-.4-.689-.38-1.078l2.147-40.968c.02-.399.2-.769.489-1.038.3-.26.689-.4 1.079-.38.399.02.768.2 1.038.49.27.299.399.689.379 1.088z"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ),
      sortable: true,
      center: true,
    },
  ];

  return (
    <PageWrapper>
      <div className="container bg-white p-20">
        <h2 className=" mb-16 text-2xl text-blue-800 font-bold">
          All Prodcut Category
        </h2>
        <div className=" grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-md font-bold mb-2"
              for="categoryName"
            >
              Add Product Category
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="categoryName"
              name="categoryName"
              onChange={(e) => setCotegoryName(e.target.value)}
              value={categoryName}
              type="text"
              placeholder="Enter Category Name"
            />
          </div>
          <div className="mt-[30px]">
            {buttontype == "Add" ? (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-5 rounded focus:outline-none focus:shadow-outline "
                type="button"
                onClick={addCategory}
              >
                Add
              </button>
            ) : (
              <div>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-5 rounded focus:outline-none focus:shadow-outline "
                  type="button"
                  onClick={updateCateogry}
                >
                  update
                </button>
                <button
                  className="bg-blue-500 mx-2 hover:bg-blue-700 text-white font-bold py-2 px-5 rounded focus:outline-none focus:shadow-outline "
                  type="button"
                  onClick={() => {
                    setCotegoryName("");
                    setButtonType("Add");
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>

        <DataTable columns={columns} data={Allcategory} />
      </div>
    </PageWrapper>
  );
};

export default ProductCategory;
