import React, { useEffect, useState, lazy, Suspense } from "react";
import ContentBox from "../components/contents/ContentBox";
import LoadingIndicator from "../components/LoadingIndicator";
import PageWrapper from "../components/PageWrapper";
import SortByTag from "../components/SortByTag";
import ProductAddForm from "../components/addProduct";
import { getData } from "../FatchApi/FatchApi";
import BlogAddForm from "../components/BlogAddForm";

const Blog = lazy(() => import("../components/Blogs/Blog"));

const ManageBlog = () => {
  const [show, setshow] = useState(true);
  const [Blogs, setBlogs] = useState([]);
  const [editID, setEditID] = useState(null);

  // Fetch products from server
  const getAllBlogs = async () => {
    const res = await getData("api/blog/get-blogs");
    console.log(res.blogs);

    setBlogs(res.blogs);
  };
  useEffect(() => {
    getAllBlogs();
  }, []);

  const openhandler = () => {
    setshow(!show);
    setEditID(false);
  };

  return (
    <PageWrapper>
      <div className="w-full">
        {show ? (
          <div style={{ display: "flex" }}>
            <div>
              <h1 className="text-lg text-gray-100 font-bold leading-5">
                Sapphire Dry Nuts Blogs
              </h1>
              <p className="text-gray-300 leading-5">
                Enhance your business by sharing your store URL.
              </p>
            </div>
            <div className="text-right" style={{ marginLeft: "auto" }}>
              <button
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                onClick={() => openhandler()}
              >
                Add Blog +
              </button>
            </div>
          </div>
        ) : (
          <div className="text-right">
            <button
              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
              onClick={() => setshow(!show)}
            >
              Show All Blogs
            </button>
          </div>
        )}

        {!show ? (
          <BlogAddForm id={editID}></BlogAddForm>
        ) : (
          <ContentBox name="Active Blogs">
            <>
              <div className="border-b border-b-glitch-box">
                <SortByTag tags={[]} />
              </div>

              {/* Products list */}
              <div className="mt-5"></div>
              <Suspense fallback={<LoadingIndicator />}>
                <ul className="sm:px-5 px-1 bg-transparent">
                  {Blogs &&
                    Blogs.map((res, i) => (
                      <li
                        className="block mt-4 pb-4 border-b border-b-glitch-box last:border-none"
                        key={i}
                      >
                        <Blog
                          data={res}
                          onchange={getAllBlogs}
                          setshow={setshow}
                          setEditID={setEditID}
                        />
                      </li>
                    ))}
                </ul>
              </Suspense>
            </>
          </ContentBox>
        )}
      </div>
    </PageWrapper>
  );
};

export default ManageBlog;
