import React, { useState, useEffect } from "react";
import PageWrapper from "../components/PageWrapper";

import { getData } from "../FatchApi/FatchApi";
import Image from "../components/Image";
import { Link } from "react-router-dom";

const ManageImages = () => {
  const [allImages, setAllImages] = useState("");

  const getImages = async () => {
    const res = await getData("api/images/get-all-images");
    setAllImages(res.Banners);
  };
  useEffect(() => {
    getImages();
  }, []);

  return (
    <PageWrapper>
      <section className="bg-gradient-to-b from-gray-200 to-gray-400 bg-fixed h-full  pt-5">
        <div className="text-right" style={{ marginLeft: "auto" }}>
          <Link to="/manage-images/Add-images">
            <button className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
              Update Images
            </button>
          </Link>
        </div>
        <div className="container mx-auto ">
          <h1 className="text-center text-4xl text-white mb-2">Hero Banners</h1>
          {/* <p className="text-center text-gray-800 mb-8">
                    Built by{' '}
                    <a
                        className="underline hover:no-underline"
                        href="http://www.scottturner.co.uk"
                        target="_blank"
                    >
                        Garni Foods
                    </a>
                </p> */}

          <div className="grid gap-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3 sm:mb-16">
            {allImages?.heroBanner?.map((item, i) => (
              <div
                class="bg-white rounded-md overflow-hidden relative shadow-md"
                key={item}
              >
                <div>
                  <img class="w-full" src={item} alt="Recipe Title" />
                </div>
                <div class="p-2">
                  <h2 class="text-2xl  text-center">Hero Banners {i + 1}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-gradient-to-b from-gray-200 to-gray-400 bg-fixed h-full  ">
        <div className="container mx-auto ">
          <div className="text-right" style={{ marginLeft: "auto" }}>
            <Link to="/manage-images/Add-images">
              <button className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
                Update Images
              </button>
            </Link>
          </div>
          <h1 className="text-center text-4xl text-white mb-2">
            Other Banners
          </h1>
          <div className="grid gap-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3 sm:mb-16">
            <Image
              src={allImages?.homePramotionBanner}
              cate={"Pramotion Homepage Banner"}
            />
            <Image
              src={allImages?.homeGiftBanner}
              cate={"Gift Banner Homepage"}
            />
            <Image
              src={allImages?.contactUsBanner}
              cate={"Contact Us Banner"}
            />
            <Image src={allImages?.aboutUsBanner} cate={"About Us Banner"} />
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};

export default ManageImages;
