import React, { useState } from "react";
import { postData } from "../FatchApi/FatchApi";
import PageWrapper from "../components/PageWrapper";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Loader/Spinner";

const UpdateImage = () => {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [productData, setProductData] = useState({
    heroBanner: "",
    homePramotionBanner: "",
    homeGiftBanner: "",
    contactUsBanner: "",
    aboutUsBanner: "",
  });

  const handleSingleFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;

    setProductData((prevData) => ({
      ...prevData,
      [name]: file,
    }));
  };

  const handleMultiFileChange = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);
    const name = e.target.name;
    const fileURLs = fileArray.map((file) => file[0]);
    setProductData((prevData) => ({
      ...prevData,
      [name]: fileArray,
      imageUrls: fileURLs,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const contanttype = "";

    const formData = new FormData();
    if (productData.heroBanner.length !== 0) {
      productData.heroBanner.forEach((image, index) => {
        formData.append(`heroBanner`, image);
      });
    }

    if (productData.homePramotionBanner !== "") {
      formData.append("homePramotionBanner", productData.homePramotionBanner);
    }
    if (productData.homeGiftBanner !== "") {
      formData.append("homeGiftBanner", productData.homeGiftBanner);
    }
    if (productData.aboutUsBanner !== "") {
      formData.append("aboutUsBanner", productData.aboutUsBanner);
    }
    if (productData.contactUsBanner !== "") {
      formData.append("contactUsBanner", productData.contactUsBanner);
    }
    const res = await postData("api/images/images-upload", {
      data: formData,
      contanttype,
    });
    console.log(res);
    if (res.success) {
      setSpinner(false);
      navigate("/manage-images");
    } else {
      setSpinner(false);
      alert(res.message);
    }
  };

  return (
    <>
      {spinner ? <Spinner /> : ""}
      <PageWrapper>
        <form encType="multipart/form-data" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4 mx-auto">
            <div className="mb-4">
              <label
                htmlFor="heroBanner"
                className="block mb-1 font-medium text-white"
              >
                Hero Banner (Please selelet all images)
              </label>

              <input
                type="file"
                id="heroBanner"
                name="heroBanner"
                onChange={handleMultiFileChange}
                multiple
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="homePramotionBanner"
                className="block mb-1 font-medium text-white"
              >
                Pramotion Banner Home (Please selelet 1 image)
              </label>

              <input
                type="file"
                id="homePramotionBanner"
                name="homePramotionBanner"
                onChange={handleSingleFileChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="homeGiftBanner"
                className="block mb-1 font-medium text-white"
              >
                Gift Banner Home (Please selelet 1 image)
              </label>

              <input
                type="file"
                id="homeGiftBanner"
                name="homeGiftBanner"
                onChange={handleSingleFileChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="aboutUsBanner"
                className="block mb-1 font-medium text-white"
              >
                About Us Banner (Please selelet 1 image)
              </label>

              <input
                type="file"
                id="aboutUsBanner"
                name="aboutUsBanner"
                onChange={handleSingleFileChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="contactUsBanner"
                className="block mb-1 font-medium text-white"
              >
                Contact Us (Please selelet 1 image)
              </label>

              <input
                type="file"
                id="contactUsBanner"
                name="contactUsBanner"
                onChange={handleSingleFileChange}
              />
            </div>
          </div>
          <div className="mb-4 bg-white">
            <button
              className="px-4 py-2 mt-5  text-white bg-blue-500 rounded hover:bg-blue-600 float-right text-center"
              type="submit"
            >
              Upload
            </button>
          </div>
        </form>
      </PageWrapper>
    </>
  );
};

export default UpdateImage;
