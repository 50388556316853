export const BaseURL = "https://api.merakimix.in";

// const postData = async (url, body) => {

//     console.log(bo)

//     const Token = localStorage.getItem('token');

//         const response = await fetch(`${BaseURL}/${url}`, {
//             method: "POST",
//             headers: {
//                 // 'Authorization': `Bearer ${Token}`,
//                 // 'Content-Type': 'application/json',
//                 // Accept: 'application/json',
//             },
//             body:body,
//         });

//         try {
//             const result1 = await response.json();
//             return result1;
//         } catch (e) {
//             console.error(e);
//         }
// };

const postData = async (url, formData) => {
  const Token = localStorage.getItem("token");
  console.log(formData, url);
  if (formData.contanttype) {
    const response = await fetch(`${BaseURL}/${url}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": formData.contanttype,
      },
      body: formData.data,
    });

    try {
      const result = await response.json();
      return result;
    } catch (e) {
      console.error(e);
    }
  } else {
    const response = await fetch(`${BaseURL}/${url}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formData.data,
    });

    try {
      const result = await response.json();
      return result;
    } catch (e) {
      console.error(e);
    }
  }
};

const putData = async (url, formData) => {
  const Token = localStorage.getItem("token");
  const response = await fetch(`${BaseURL}/${url}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${Token}`,
      //"Content-Type": "application/json",
    },
    body: formData.data,
  });
  try {
    const result1 = await response.json();
    return result1;
  } catch (e) {
    console.error(e);
  }
};

const putData2 = async (url, formData) => {
  const Token = localStorage.getItem("token");
  const response = await fetch(`${BaseURL}/${url}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    },
    body: formData.data,
  });
  try {
    const result1 = await response.json();
    return result1;
  } catch (e) {
    console.error(e);
  }
};

const getData = async (path) => {
  const Token = localStorage.getItem("token");

  const response = await fetch(`${BaseURL}/${path}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    },
  });
  try {
    const result2 = await response.json();
    return result2;
  } catch (e) {
    return e;
  }
};

const DeleteData = async (url) => {
  const Token = localStorage.getItem("token");
  const response = await fetch(`${BaseURL}/${url}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    // body: JSON.stringify(body),
  });
  try {
    const result1 = await response.json();
    return result1;
  } catch (e) {
    console.error(e);
  }
};

export { postData, getData, DeleteData, putData, putData2 };
