import React from "react";

const Image = ({ src, cate }) => {
  return (
    <div
      class="bg-white rounded-md overflow-hidden relative shadow-md h-[300px]"
      key={""}
    >
      <div>
        <img class="w-full h-auto" src={src} alt="Recipe Title" />
      </div>
      <div class="p-2 h-">
        <h2 class="text-2xl  text-center">{cate}</h2>
      </div>
    </div>
  );
};

export default Image;
