import React, { useEffect, useState } from "react";
import PageWrapper from "../components/PageWrapper";
import {
  BaseURL,
  DeleteData,
  getData,
  postData,
  putData,
  putData2,
} from "../FatchApi/FatchApi";
import DataTable from "react-data-table-component";

const DiscountAndCoupon = () => {
  const [discountPercentage, setdiscountPercentage] = useState(0);
  const [aboveValue, setAboveValue] = useState();
  const [freeDeliveryAbove, setFreeDeliveryAbove] = useState(1000);
  const [getdiscount, setGetdiscount] = useState();
  const [AllCoupons, setAllCoupons] = useState([]);
  const [buttontype, setButtonType] = useState("add");
  const [couponId, setCouponId] = useState("");
  const [coupon, setCoupon] = useState({
    code: "",
    discountType: "",
    discountValue: "",
    discountOnPurchase: "",
    expirationDate: "",
    usageLimit: "",
  });
  const getDiscount = async () => {
    const res = await getData("api/discount");
    setGetdiscount(res[0]);
  };
  const getAllCoupon = async () => {
    const res = await getData("api/coupon/get-all-coupon");

    setAllCoupons(res.coupon);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { discountPercentage, aboveValue, freeDeliveryAbove };
    const Token = localStorage.getItem("token");
    const response = await fetch(`${BaseURL}/api/discount/add`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      alert("Discount Add Successfuly");
      getDiscount();
    }
  };
  const deleteCoupon = async (id) => {
    const confirm = window.confirm("Do you wan't to delete ?");
    if (confirm) {
      const res = await DeleteData(`api/coupon/delete-single-coupon/${id}`);
      if (res.success) {
        getAllCoupon();
      } else {
        alert(res.message);
      }
    }
  };
  useEffect(() => {
    getDiscount();
    getAllCoupon();
  }, []);
  const toggleModal = () => {
    const modal = document.getElementById("crud-modal");
    modal.classList.toggle("hidden");
  };

  const closeModal = () => {
    const modal = document.getElementById("crud-modal");
    modal.classList.toggle("hidden");
    setCoupon({
      code: "",
      discountType: "",
      discountValue: "",
      discountOnPurchase: "",
      expirationDate: "",
      usageLimit: "",
    });

    setButtonType("add");
  };
  const setFormForUpdate = (row) => {
    const date = new Date(row.expirationDate);
    const formattedDate = date.toISOString().split("T")[0];
    setCoupon({
      code: row.code,
      discountType: row.discountType,
      discountValue: row.discountValue,
      discountOnPurchase: row.discountOnPurchase,
      expirationDate: formattedDate,
      usageLimit: row.usageLimit,
    });
    toggleModal();
    setButtonType("update");
    setCouponId(row._id);
  };
  const columns = [
    {
      name: "SR.Number",
      selector: (row, i) => 1 + i,
      sortable: true,
      center: true,
    },
    {
      name: "Coupon Code",
      selector: (row, i) => row.code,
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Discount Type in",
      selector: (row, i) => row.discountType,
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Discount Value (%)",
      selector: (row, i) => row.discountValue,
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Discount on Purchase",
      selector: (row, i) => row.discountOnPurchase,
      sortable: true,
      center: true,
      width: "180px",
    },
    {
      name: "Expair Date",
      selector: (row, i) => new Date(row.expirationDate).toString(),
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Usage Limit",
      selector: (row, i) => row.usageLimit,
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: "Used By ",
      selector: (row, i) => row.usedCount,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row, i) => (
        <div className=" flex flex-row ">
          <div className="mx-2">
            <svg
              role="button"
              onClick={() => {
                setFormForUpdate(row);
              }}
              version="1.1"
              className="text-blue-600"
              fill="currentColor"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              height="20px"
              width="20px"
              viewBox="0 0 348.882 348.882"
              style={{ enableBackground: "new 0 0 348.882 348.882" }}
              xmlSpace="preserve"
            >
              <g>
                <path
                  d="M333.988,11.758l-0.42-0.383C325.538,4.04,315.129,0,304.258,0c-12.187,0-23.888,5.159-32.104,14.153L116.803,184.231
		c-1.416,1.55-2.49,3.379-3.154,5.37l-18.267,54.762c-2.112,6.331-1.052,13.333,2.835,18.729c3.918,5.438,10.23,8.685,16.886,8.685
		c0,0,0.001,0,0.001,0c2.879,0,5.693-0.592,8.362-1.76l52.89-23.138c1.923-0.841,3.648-2.076,5.063-3.626L336.771,73.176
		C352.937,55.479,351.69,27.929,333.988,11.758z M130.381,234.247l10.719-32.134l0.904-0.99l20.316,18.556l-0.904,0.99
		L130.381,234.247z M314.621,52.943L182.553,197.53l-20.316-18.556L294.305,34.386c2.583-2.828,6.118-4.386,9.954-4.386
		c3.365,0,6.588,1.252,9.082,3.53l0.419,0.383C319.244,38.922,319.63,47.459,314.621,52.943z"
                />
                <path
                  d="M303.85,138.388c-8.284,0-15,6.716-15,15v127.347c0,21.034-17.113,38.147-38.147,38.147H68.904
		c-21.035,0-38.147-17.113-38.147-38.147V100.413c0-21.034,17.113-38.147,38.147-38.147h131.587c8.284,0,15-6.716,15-15
		s-6.716-15-15-15H68.904c-37.577,0-68.147,30.571-68.147,68.147v180.321c0,37.576,30.571,68.147,68.147,68.147h181.798
		c37.576,0,68.147-30.571,68.147-68.147V153.388C318.85,145.104,312.134,138.388,303.85,138.388z"
                />
              </g>
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
            </svg>
          </div>
          <div>
            <svg
              role="button"
              onClick={() => deleteCoupon(row._id)}
              className="text-red-600"
              fill="currentColor"
              height="20px"
              viewBox="733 450 64.901 90"
              width="20px"
              xmlns="http://www.w3.org/2000/svg"
              isolation="isolate"
            >
              <path
                d="m755.532 457.988h19.838c.036-.129.055-.264.055-.4v-3.195c0-.369-.139-.729-.399-.988-.27-.26-.619-.41-.989-.41h-17.173c-.37 0-.719.15-.989.41-.259.259-.399.619-.399.988v3.195c0 .136.019.271.056.4zm-18.368 2.995h56.574c.309 0 .609.12.828.34.22.219.34.519.34.829v5.361c0 .31-.12.609-.34.819-.219.22-.519.35-.828.35h-56.574c-.31 0-.609-.13-.829-.35-.22-.21-.34-.509-.34-.819v-5.361c0-.31.12-.61.34-.829.22-.22.519-.34.829-.34zm29.794 64.043c0 .399-.159.779-.439 1.068-.28.28-.659.429-1.058.429-.4 0-.779-.149-1.059-.429-.279-.289-.439-.669-.439-1.068v-41.018c0-.399.16-.779.439-1.058.28-.28.659-.44 1.059-.44.399 0 .778.16 1.058.44.28.279.439.659.439 1.058zm-13.06-41.068 2.147 40.968c.02.389-.11.779-.38 1.078-.269.3-.639.47-1.038.489-.389.02-.779-.109-1.078-.379-.29-.269-.47-.639-.49-1.038l-2.146-40.958c-.02-.399.11-.789.379-1.088.27-.29.639-.47 1.039-.49.389-.02.778.12 1.078.38.289.269.469.639.489 1.038zm-12.783-12.285 3.867 63.546c0 .311.11.622.289.849.19.215.44.347.709.347h38.941c.27 0 .519-.132.709-.347.18-.227.289-.538.289-.849l3.867-63.546zm11.378-13.685h-15.334c-2.295 0-4.159 1.864-4.159 4.159v5.367c0 2.295 1.864 4.159 4.159 4.159h.96l3.867 63.544c0 2.64 1.79 4.783 3.994 4.783h38.941c2.204 0 3.994-2.143 3.994-4.783l3.867-63.544h.96c2.295 0 4.159-1.864 4.159-4.159v-5.367c0-2.295-1.864-4.159-4.159-4.159h-15.334c.012-.131.018-.263.018-.397v-3.2c0-2.423-1.968-4.391-4.391-4.391h-17.168c-2.424 0-4.391 1.968-4.391 4.391v3.2c0 .134.006.266.017.397zm27.515 26.13-2.146 40.958c-.02.399-.2.769-.49 1.038-.299.27-.689.399-1.078.379-.399-.019-.769-.189-1.038-.489-.27-.299-.4-.689-.38-1.078l2.147-40.968c.02-.399.2-.769.489-1.038.3-.26.689-.4 1.079-.38.399.02.768.2 1.038.49.27.299.399.689.379 1.088z"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ),
      sortable: true,
      center: true,
    },
  ];

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setCoupon({
      ...coupon,
      [name]: value,
    });
  };

  const createCoupon = async (e) => {
    const body = {
      code: coupon.code,
      discountType: coupon.discountType,
      discountValue: coupon.discountValue,
      discountOnPurchase: coupon.discountOnPurchase,
      expirationDate: coupon.expirationDate,
      usageLimit: coupon.usageLimit,
    };
    const formData = {
      contanttype: "application/json",
      data: JSON.stringify(body),
    };
    const res = await postData("api/coupon/create-coupon", formData);

    if (res.success) {
      toggleModal();
      getAllCoupon();
      setCoupon({
        code: "",
        discountType: "",
        discountValue: "",
        discountOnPurchase: "",
        expirationDate: "",
        usageLimit: "",
      });
    }
  };
  const updateCoupon = async (e) => {
    e.preventDefault();
    const body = {
      code: coupon.code,
      discountType: coupon.discountType,
      discountValue: coupon.discountValue,
      discountOnPurchase: coupon.discountOnPurchase,
      expirationDate: coupon.expirationDate,
      usageLimit: coupon.usageLimit,
    };
    const formData = {
      data: JSON.stringify(body),
    };

    const res = await putData2(
      `api/coupon/update-coupon/${couponId}`,
      formData
    );

    if (res.success) {
      getAllCoupon();
      closeModal();
    } else {
      alert(res.message);
      getAllCoupon();
      closeModal();
    }
  };
  return (
    <PageWrapper>
      <div className="container">
        <div className="my-5">
          <h2 className="text-[25px] p-5 text-blue-700 bg-white my-10 text-center font-bold leading-5">
            Discount on Website
          </h2>
          <div className="grid grid-cols-2 gap-4">
            <form
              onSubmit={handleSubmit}
              className="bg-gray-100 p-6 rounded-lg shadow-md grid grid-cols-2 gap-2"
            >
              <div className="mb-1">
                <label
                  htmlFor="discountPercentage"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Discount Percentage
                </label>
                <input
                  type="number"
                  id="discountPercentage"
                  value={discountPercentage}
                  onChange={(e) => setdiscountPercentage(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
              </div>

              <div className="mb-1">
                <label
                  htmlFor="aboveValue"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Above Value
                </label>
                <input
                  type="number"
                  id="aboveValue"
                  value={aboveValue}
                  onChange={(e) => setAboveValue(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
              </div>

              <div className="mb-1">
                <label
                  htmlFor="freeDeliveryAbove"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Free Delivery Above
                </label>
                <input
                  type="number"
                  id="freeDeliveryAbove"
                  value={freeDeliveryAbove}
                  onChange={(e) => setFreeDeliveryAbove(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
              </div>

              <button type="submit">
                <span className="bg-indigo-500  text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition duration-300">
                  Apply Discount
                </span>
              </button>
            </form>

            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <div className="grid grid-cols-3">
                <h2 className="text-2xl text-gray-700 font-semibold col-span-2 mb-4">
                  Discount Percentage :
                </h2>
                <h2 className="text-2xl text-gray-700 font-semibold mb-4">
                  {getdiscount?.discountPercentage} %
                </h2>
              </div>
              <div className="grid grid-cols-3">
                <h2 className="text-2xl text-gray-700 font-semibold col-span-2 mb-4">
                  Above Value :
                </h2>
                <h2 className="text-2xl text-gray-700 font-semibold mb-4">
                  ₹ {getdiscount?.aboveValue}
                </h2>
              </div>
              <div className="grid grid-cols-3">
                <h2 className="text-2xl text-gray-700 font-semibold col-span-2 mb-4">
                  Free Delivery Above :
                </h2>
                <h2 className="text-2xl text-gray-700 font-semibold mb-4">
                  ₹ {getdiscount?.freeDeliveryAbove}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <p className=" bg-white p-3 mt-10 text-center text-xl font-bold text-blue-700">
          Your Coupons
        </p>
        <div className="my-3 flex justify-end">
          <button
            data-modal-target="crud-modal"
            data-modal-toggle="crud-modal"
            className="my-5 block text-end text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
            onClick={toggleModal}
          >
            Create Coupon
          </button>
        </div>
        <div className="grid grid-cols-1">
          <DataTable columns={columns} data={AllCoupons} />
        </div>
      </div>

      {/* Main modal */}
      <div
        id="crud-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="hidden overflow-y-auto overflow-x-hidden fixed t z-50 justify-center items-center w-full md:inset-0  max-h-full"
      >
        <div className="relative p-4 w-full max-w-md max-h-full mx-auto">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Create New Coupon
              </h3>
              <button
                type="button"
                onClick={closeModal}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <form className="p-4 md:p-5">
              <div className="grid gap-4 mb-4 grid-cols-2">
                <div className="col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Coupone Code
                  </label>
                  <input
                    type="text"
                    name="code"
                    id="code"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="SPPHI_30PER"
                    value={coupon.code}
                    required
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="discountOnPurchase"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Dicount On Purchase
                  </label>
                  <input
                    type="number"
                    name="discountOnPurchase"
                    id="discountOnPurchase"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="000"
                    value={coupon.discountOnPurchase}
                    onChange={onChangeHandler}
                    required
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="discountType"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Discount Type
                  </label>
                  <select
                    id="discountType"
                    name="discountType"
                    onChange={onChangeHandler}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  >
                    <option defaultValue>Select category</option>
                    <option
                      value="percentage"
                      selected={coupon.discountType == "percentage"}
                    >
                      Percentage
                    </option>
                  </select>
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="discountValue"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Dicount Value (%)
                  </label>
                  <input
                    type="number"
                    name="discountValue"
                    id="discountValue"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="In %"
                    value={coupon?.discountValue}
                    onChange={onChangeHandler}
                    required
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="expirationDate"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Expair Date
                  </label>
                  <input
                    type="Date"
                    name="expirationDate"
                    id="expirationDate"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="In %"
                    value={coupon?.expirationDate}
                    onChange={onChangeHandler}
                    required
                  />
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <label
                    htmlFor="usageLimit"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Usage Limit
                  </label>
                  <input
                    type="number"
                    name="usageLimit"
                    id="usageLimit"
                    value={coupon?.usageLimit}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="0"
                    onChange={onChangeHandler}
                    required
                  />
                </div>
              </div>
              {buttontype == "add" ? (
                <button
                  onClick={createCoupon}
                  className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <svg
                    className="me-1 -ms-1 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Creat Coupon
                </button>
              ) : (
                <button
                  onClick={updateCoupon}
                  className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Update Coupon
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default DiscountAndCoupon;
