import React from "react";
import configs from "../../configs.json";
import { BaseURL } from "../../FatchApi/FatchApi";

const OrderDetailsProduct = ({ items, price, units, image, quantity }) => {
  return (
    <div className={"w-full"}>
      {/* Product order details */}
      {/* Product preview thumbnail */}
      {/* <img
        src={image ? image : configs.defaultProductImage}
        alt={name}
        className="max-w-xs w-full rounded block mx-auto"
      /> */}
      <div className="flex-1 mt-5">
        {items?.map((item) => {
          return (
            <>
              <div className="flex flex-wrap items-center mt-2">
                <div className="w-full md:w-1/6 mb-4 md:mb-0">
                  <img
                    src={`${BaseURL}/uploads/${item?._id?.images[0]}`}
                    className="w-[80px] h-[80px]"
                    alt="Phone"
                  />
                </div>
                <div className="w-full md:w-1/6 flex justify-center items-center">
                  <p className="text-gray-500 mb-0">{item?._id?.title}</p>
                </div>
                <div className="w-full md:w-1/6 flex justify-center items-center">
                  {/* <p className="text-gray-500 text-sm mb-0">
                                    White
                                  </p> */}
                </div>
                <div className="w-full md:w-1/6 flex justify-center items-center">
                  <p className="text-gray-500 text-sm mb-0">
                    {item?._id?.weight}
                  </p>
                </div>
                <div className="w-full md:w-1/6 flex justify-center items-center">
                  <p className="text-gray-500 text-sm mb-0">Qty: {item?.qty}</p>
                </div>
                <div className="w-full md:w-1/6 flex justify-center items-center">
                  <p className="text-gray-500 text-sm mb-0">
                    ₹{item?.qty * item?.sellingPrice}
                  </p>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default OrderDetailsProduct;
