import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import NotFoundPage from "./pages/404Page";
import CustomerListPage from "./pages/CustomerListPage";
import ManageOrderPage from "./pages/ManageOrderPage";
import PaymentPage from "./pages/PaymentPage";
import ProductPage from "./pages/ProductPage";
import OrderDetailsPage from "./pages/OrderDetailsPage";
import { PageListContext } from "./contexts/PageContext";
import Verifyotp from "./pages/Verifyotp";
import ManageUserOrder from "./pages/ManageUserOrder";
import ManageBlog from "./pages/ManageBlog";
import ManageRecipe from "./pages/ManageRecipes";
import ManageImages from "./pages/ManageImages";
import UpdateImage from "./pages/UpdateImage";
import ManageReviews from "./pages/ManageReviews";
import ReviewDetailsPage from "./pages/ReviewDetailsPage";
import ProductCategory from "./pages/ProductCategory";
import DiscountAndCoupon from "./pages/DiscountAndCoupon";
import Colors from "./pages/Colors";

/* App routes controller  */
const App = () => {
  const [pageList, setPageList] = useState([
    { label: "Dashboard", slug: "/Dashboard", icon: "dashboard" },
    { label: "Customer list", slug: "/customer-list", icon: "people_alt" },
    { label: "Manage orders", slug: "/manage-orders", icon: "leaderboard" },
    // { lable: "Manage blogs", slug: "/manage-blog", icon: "blog" },
    // {
    //   label: "Online payments",
    //   slug: "/online-payments",
    //   icon: "account_balance_wallet",
    // },
    { label: "Product Category", slug: "/product-category", icon: "shop_2" },

    { label: "Discount and Coupon", slug: "/discount-coupon", icon: "shop_2" },
    { label: "Products", slug: "/products", icon: "shop_2" },
    // { label: "Settings", slug: "/settings", icon: "settings" },
    { label: "Manage Blogs", slug: "/manage-blogs", icon: "shop" },
    { label: "Colors", slug: "/colors", icon: "shop" },
    { label: "Manage Images", slug: "/manage-images", icon: "shop" },
    { label: "Manage Reviews", slug: "/manage-reviews", icon: "shop" },
  ]);

  // Check if a token exists in local storage
  const hasToken = !!localStorage.getItem("token");

  // State to track if the user is authenticated
  const [authenticated, setAuthenticated] = useState(hasToken);

  useEffect(() => {
    // Update the authentication state based on the presence of a token
    setAuthenticated(hasToken);
  }, [hasToken]);

  return (
    <PageListContext.Provider value={{ pageList, setPageList }}>
      <BrowserRouter>
        <Routes>
          {/* Login page */}
          {authenticated ? (
            <>
              {/* Customer lists */}
              <Route
                path="/customer-list"
                caseSensitive
                element={<CustomerListPage />}
              />
              <Route
                path="/customer-list/:userId"
                caseSensitive
                element={<ManageUserOrder />}
              />
              {/* Manage orders */}
              <Route
                path="/manage-orders"
                caseSensitive
                element={<ManageOrderPage />}
              />
              {/* Manage Reviews */}
              <Route>
                <Route
                  path="/manage-reviews"
                  caseSensitive
                  element={<ManageReviews />}
                />
                <Route
                  path="manage-reviews/:id"
                  caseSensitive
                  element={<ReviewDetailsPage />}
                />
                <Route />
              </Route>

              {/* Online payments */}
              <Route
                path="/online-payments"
                caseSensitive
                element={<PaymentPage />}
              />

              {/* Order details */}
              <Route
                path="/order/:orderId"
                caseSensitive
                element={<OrderDetailsPage />}
              />
              <Route
                path="/manage-blogs"
                caseSensitive
                element={<ManageBlog />}
              />
              <Route
                path="/manage-recipes"
                caseSensitive
                element={<ManageRecipe />}
              />
              <Route
                path="/discount-coupon"
                caseSensitive
                element={<DiscountAndCoupon />}
              />
              <Route>
                <Route
                  path="/manage-images"
                  caseSensitive
                  element={<ManageImages />}
                />
                <Route
                  path="manage-images/Add-images"
                  caseSensitive
                  element={<UpdateImage />}
                />
              </Route>

              {/* Products */}
              <Route path="/products" caseSensitive element={<ProductPage />} />
              <Route
                path="/product-category"
                caseSensitive
                element={<ProductCategory />}
              />
               <Route
                path="/colors"
                caseSensitive
                element={<Colors />}
              />

              <Route
                path="/Dashboard"
                caseSensitive
                element={<DashboardPage />}
              />
            </>
          ) : (
            <>
              <Route path="/" caseSensitive element={<LoginPage />} />
              <Route path="/verify-otp" caseSensitive element={<Verifyotp />} />
            </>
          )}

          {/* 404 page not found */}
          <Route path="*" element={<DashboardPage />} />
        </Routes>
      </BrowserRouter>
    </PageListContext.Provider>
  );
};

export default App;
